import React, { Component } from "react";
import "./App.css";
// Azure AD authentication
import * as azureAD from "./utils/AzureAD";
import * as graph from "./utils/Graph";
// @ukhc/devops-react-library
import AppView from "@ukhc/devops-react-library/components/AppView";
import LandingPage from "@ukhc/devops-react-library/components/LandingPage";
// App components
import Settings from "./components/Settings";
import Languages from "./components/Languages";
import LanguageMenu from "./components/LanguageMenu";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutModalShow: false,
      selectedView: "",
      error: null,
      isAuthenticated: false,
      isAuthorized: false,
      isSigningIn: true, // true here for first load
      user: {},
    };

    this.getUserProfile = this.getUserProfile.bind(this);
    this.handleShowAboutModal = this.handleShowAboutModal.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
  }

  // for the data refresh interval
  intervalID;

  componentDidMount() {
    this.getUserProfile();
    // Refresh all tokens and user identity data on a schedule
    this.intervalID = setInterval(this.getUserProfile, 180000);
  }

  componentWillUnmount() {
    /*
          stop token refresh from continuing to run even
          after unmounting this component.
      */
    clearInterval(this.intervalID);
  }

  async login() {
    if (window.REACT_APP_DEBUG) console.log("login");
    this.setState({
      isSigningIn: true,
    });
    await azureAD.login();
    this.getUserProfile();
  }

  async logout() {
    await azureAD.logout();
    this.setState({
      isAuthenticated: false,
      isAuthorized: false,
      isSigningIn: false,
      user: {},
    });
  }

  async getUserProfile() {
    // If there is a cached user we are already logged in
    var cachedUser = azureAD.getCachedUser();
    if (window.REACT_APP_DEBUG)
      console.log("cached user: " + JSON.stringify(cachedUser));

    if (cachedUser) {
      // User is authenticated
      // Get the graph token
      const graphToken = await azureAD.getToken(["user.read"]);
      if (window.REACT_APP_DEBUG)
        console.log("grapToken: " + JSON.stringify(graphToken));

      // Get user data from Graph
      if (graphToken) {
        var graphUser = await graph.getUserDetails(graphToken);
        if (window.REACT_APP_DEBUG)
          console.log("graphUser: " + JSON.stringify(graphUser));
        var graphPhoto = await graph.getUserProfilePhoto(graphToken);
      } else {
        // token fetch failed, remove user data and go
        this.setState({
          isAuthorized: false,
          isSigningIn: false,
          isAuthenticated: false,
          user: { userName: cachedUser.username },
        });
        return;
      }

      // Get the token for the app api
      const apiToken = await azureAD.getToken([window.REACT_APP_API_SCOPE]);
      if (window.REACT_APP_DEBUG)
        console.log("apiToken: " + JSON.stringify(apiToken));

      // Roles
      if (apiToken) {
        // Turn the user roles into a hierarchical thing
        // The user object will carry a bool for each role
        // We also determine a top level, "primary" role for display
        var theRoles = "";
        var thePrimaryRole = "";
        var isAuthorized = false;
        var isAdministrator = false;
        var isUser = false;
        if (apiToken.idTokenClaims.roles) {
          // Only set these if we have roles in the token.
          theRoles = apiToken.idTokenClaims.roles.toString();
          if (window.REACT_APP_DEBUG) console.log(theRoles);
          if (theRoles.includes("User")) {
            thePrimaryRole = "User";
            isAuthorized = true;
            isUser = true;
          }
          if (theRoles.includes("Administrator")) {
            thePrimaryRole = "Administrator";
            isAuthorized = true;
            isUser = true;
            isAdministrator = true;
          }
        } else {
          // user has no roles in the token
          // everyone is a user
          isAuthorized = true;
          thePrimaryRole = "User";
          isUser = true;
        }

        // Initialize the selectedView based on role
        if (this.state.selectedView === "") {
          var theSelectedView = "All Languages";
          if (!isUser) {
            theSelectedView = "All Languages";
          }
          this.setState({
            selectedView: theSelectedView,
          });
        }
      } else {
        // token fetch failed, remove user data and go
        this.setState({
          isAuthorized: false,
          isSigningIn: false,
          isAuthenticated: false,
          user: { userName: cachedUser.username },
        });
        return;
      }

      //Set the state with the new user data
      this.setState({
        isAuthenticated: true,
        isAuthorized: isAuthorized,
        isSigningIn: false,
        user: {
          displayName: graphUser.displayName,
          email: graphUser.mail || graphUser.userPrincipalName,
          isAdministrator: isAdministrator,
          isUser: isUser,
          primaryRole: thePrimaryRole,
          photo: graphPhoto,
          roles: theRoles,
          graphToken: graphToken,
          apiToken: apiToken,
          userName: apiToken.account.username,
        },
        error: null,
      });
    } else {
      // there was no user in the browser cache
      this.setState({
        isAuthenticated: false,
        isAuthorized: false,
        isSigningIn: false,
        user: {},
      });
    }
  }

  handleChangeView(view) {
    this.setState({
      selectedView: view,
    });
  }

  handleShowAboutModal() {
    if (window.REACT_APP_DEBUG) console.log("handleShowAboutModal");
    this.setState({
      aboutModalShow: true,
    });
  }

  render() {
    if (window.REACT_APP_DEBUG) {
      console.log("isAuthenticated: " + this.state.isAuthenticated);
      console.log(JSON.stringify(this.state.user));
    }

    // Build the array of nav links for the header
    let navigation = [
      {
        name: "All Languages",
        sub: [
          { name: "Arabic" },
          { name: "French" },
          { name: "Russian" },
          { name: "Spanish" },
          { name: "Swahili" },
          { name: "Nepali" },
          { name: "Japanese" },
          { name: "Kinyarwanda" },
          { name: "Mandarin" },
          { name: "Ukrainian" },
        ],
      },
    ];

    var contentView = <div />;
    switch (this.state.selectedView) {
      case "Settings":
        contentView = <Settings user={this.state.user} />;
        break;
      case "All Languages":
        contentView = (
          <LanguageMenu
            user={this.state.user}
            handleChangeView={this.handleChangeView}
          />
        );
        break;
      case "Arabic":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      case "French":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      case "Russian":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      case "Spanish":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      case "Swahili":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      case "Nepali":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      case "Japanese":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      case "Kinyarwanda":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      case "Mandarin":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      case "Ukrainian":
        contentView = (
          <Languages user={this.state.user} lang={this.state.selectedView} handleChangeView={this.handleChangeView} />
        );
        break;
      default:
        contentView = <Languages user={this.state.user} />;
    }

    /* Login Screen */
    if (!this.state.isAuthenticated || !this.state.isAuthorized) {
      return (
        <LandingPage
          applicationName={window.REACT_APP_NAME}
          applicationDescription={window.REACT_APP_DESCRIPTION}
          isAuthenticated={this.state.isAuthenticated}
          isAuthorized={this.state.isAuthorized}
          isSigningIn={this.state.isSigningIn}
          login={this.login}
          logout={this.logout}
          userName={this.state.user.userName}
        />
      );
    }

    /* Main App View */
    return (
      <AppView
        applicationName={window.REACT_APP_NAME}
        applicationVersion={window.REACT_APP_VERSION}
        contentView={contentView}
        navigation={navigation}
        handleChangeView={this.handleChangeView}
        logout={this.logout}
        selectedView={this.state.selectedView}
        showSettingsGear={false}
        showSideNavbar={false}
        user={this.state.user}
      />
    );
  }
}

export default App;
