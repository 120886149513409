import React from "react";
import { prompts } from "./../prompts";

class AudioWorker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
    };
  }

  playPrompt(e, audio) {
    var promptFile = new Audio(audio);
    promptFile.play();
  }

  render() {
    let lang = this.props.selectedView;
    let baseURL = "https://ukhcdevops-cdn.azureedge.net/language-prompts/";

    prompts.forEach((phrase) => {
      phrase["url"] = baseURL + lang + phrase.id + ".mp3";
    });
    return (
      <div className="row">
        {prompts.map((audio, index) => (
          <button
            className="btn btn-outline-dark btn-audio mx-auto my-1 py-3 d-flex align-items-center justify-content-between"
            key={index}
            id={"audioPrompt" + index}
            style={{ width: "80%" }}
            onClick={(e) => {
              this.playPrompt(e, audio.url);
            }}
          >
            <div className="play-btn-container flex-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 18v-12l10 6-10 6z"/>
              </svg>
            </div>
            <div className="text-container flex-item text-center">
              <span language={this.props.selectedView}>{audio.phrase}</span>
            </div>
            <div className="flex-item ph"></div>
          </button>
        ))}
      </div>
    );
  }
}
export default AudioWorker;