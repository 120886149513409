import React from "react";
import { languages } from "./../prompts";
import "./component.css";

class LanguageMenu extends React.Component {
  constructor(props) {
    super(props);
  }
    render() {
        return(
            <div className="container-fluid">
                <div class="d-flex row row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 justify-content-center text-center mx-2">
                {languages.map((language, index) => (
                    <button value={language.language} key={index} className="langMenuButton btn btn-lg btn-success btn-block mx-3 my-3 px-2" view={language.language} onClick={()=> {this.props.handleChangeView(language.language)}} style={{wordWrap:"break-word"}}><span className="display-4">{language.language}</span></button>
                ))}
                </div>
            </div>
        )
    }
}

export default LanguageMenu;
