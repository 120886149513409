import React from "react";
import AudioWorker from "./Worker";

class Languages extends React.Component {
  render() {
    let lang = this.props.lang;
        return (
            <>
            <span className="px-4 my-4 font-weight-light all-lng" onClick={() => this.props.handleChangeView("All Languages")}>&larr; All Languages</span>
            <h2 className="p-2 px-4">{lang}</h2>
            <div className="container d-flex justify-content-between">
            <AudioWorker selectedView={lang} />
            </div>
            </>
        );

    }
}

export default Languages;
