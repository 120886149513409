export const languages = [
    {
      language: "French"
    },
    {
      language: "Russian"
    },
    {
      language: "Spanish"
    },
    {
      language: "Nepali"
    },
    {
      language: "Arabic"
    },
    {
      language: "Mandarin"
    },
    {
      language: "Kinyarwanda"
    },
    {
      language: "Japanese"
    },
    {
      language: "Swahili"
    },
    {
      language: "Ukrainian"
    }
];

export const prompts = [
    {id: "01", phrase: "We're going to the OR now."},
    {id: "02", phrase: "We are going to move you now. You don't have to do anything, just cross your arms over your chest and we will move you."},
    {id: "03", phrase: "We are going to attach some monitors to watch you closely during your procedure."},
    {id: "04", phrase: "We are giving you medication now and it may feel like it burns for a moment."},
    {id: "05", phrase: "You’re safe, take a deep breath and you will go to sleep. We will be here the whole time. We will take good care of you."},
    {id: "06", phrase: "Surgery is finished now and you are just waking up.  We will take you to a recovery room. "},
    {id: "07", phrase: "Please move this way."},
    {id: "08", phrase: "Open your mouth please."},
    {id: "09", phrase: "This mask is for oxygen."},
    {id: "10", phrase: "Please take some deep breaths."},
    {id: "11", phrase: "Stay still, please don't move."},
    {id: "12", phrase: "Don't touch your face."},
    {id: "13", phrase: "Open your eyes."}
]